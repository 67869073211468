import React from "react"

function NotFoundPage() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="200"
        height="200"
      >
        <path
          className="heroicon-ui"
          d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
        />
      </svg>
      <p className="text-4xl">Page not found!</p>
      <p className="text-lg px-6">We´re sorry but this page doesn´t exist.</p>

      <a href="/" className="text-blue-500 text-xl mt-8">
        Back to home
      </a>
    </div>
  )
}

export default NotFoundPage
